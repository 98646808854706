<template>
    <div class="popup-item success-popup">
        <div class="success-popup__icon">
            <i class="success-popup__icon-item"></i>
        </div>
        <h4 class="success-popup__title">Поздравляем</h4>
        <p class="success-popup__text">
            Вам открыт доступ к календарю с подарками. Заходите каждый день, чтобы собрать все бонусы.
        </p>
        <div class="success-popup__button">
            <Button :click="closePopup" :styles="{'font-weight': 600}">Принять</Button>
        </div>
    </div>
</template>

<script>
import {popupMixin} from "../../mixins/popup"
import Button from "./Button"

export default {
    mixins: [popupMixin],
    components: {
        Button
    }
}
</script>

<style lang="scss">
    .success-popup {
        
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;

            &-item {
                display: block;
                width: 60px;
                height: 80px;
                background-size: 58px 80px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('../../assets/gift.png');
            }
        }

        &__title {
            font-size: 30px;
            line-height: 32px;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        &__text {
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
</style>